import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { graphql } from 'gatsby';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { GQLTagListingQuery, GQLSitePageContext } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { SocialLink } from '../../components/SocialLink';
import { FluidObject } from 'gatsby-image';
import { BlogPostCardGrid } from '../../components/BlogPostCardGrid';
import { TBlogPostCardP } from '../../components/BlogPostCard';

type MdrEdge = GQLTagListingQuery['allMarkdownRemark']['edges'][0];

type ClassKey = 'main';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: { padding: theme.spacing(4) }
}));

export type TBlogListingTemplateP = {
    data: GQLTagListingQuery;
    pageContext: GQLSitePageContext;
};

function BlogListing(props: TReactFCP<TBlogListingTemplateP>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { tagHero, allMarkdownRemark }: GQLTagListingQuery = props.data;
    const { tag, slug }: GQLSitePageContext = props.pageContext;
    const posts: TBlogPostCardP[] = allMarkdownRemark!.edges.map(({ node: post }: MdrEdge) => ({
        tags: post.frontmatter!.tags as string[] || [],
        slug: post.fields!.slug!,
        title: post.frontmatter!.title!,
        spoiler: post.frontmatter!.spoiler!,
        timeToRead: post!.timeToRead!,
        publishDate: post.frontmatter!.publishDate!,
        fluidImage: post.frontmatter!.hero!.childImageSharp!.fluid! as FluidObject
    }));
    return (
        <main className={styles.main}>
            <SEO
                slug={slug!}
                title={`${tag!} Articles`}
                description={`A collection of serious thoughts, ideas, and reflections on ${tag!}`}
                image={{
                    src: tagHero!.childImageSharp!.original!.src!,
                    width: tagHero!.childImageSharp!.original!.width!,
                    height: tagHero!.childImageSharp!.original!.height!,
                }}
            />
            <Typography display='block' variant='h1' align='center'>{`${tag!} Articles`}</Typography>
            <Typography display='block' variant='subtitle2' align='center' color='lighter' paragraph>
                {`Deep thoughts here, shenanigans on `}
                <SocialLink variant='twitter' enableText disableIcon enableUnderline bold />
            </Typography>
            <BlogPostCardGrid posts={posts} />
        </main>
    );
}

export default (props: TReactFCP<TBlogListingTemplateP>): TReactFCR => <Page component={BlogListing} cprops={props} />;

export const query = graphql`
    query TagListing($tag: String) {
        tagHero: file(relativePath: {eq: "images/tag-hero.jpg"}) {
            childImageSharp {
                original {
                    src
                    width
                    height
                }
            }
        }
        allMarkdownRemark(
            sort: { fields: [frontmatter___publishDate], order: DESC },
            filter: { frontmatter: { tags: { in: [ $tag ] } } }
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        publishDate(formatString: "MMM DD, YYYY")
                        spoiler
                        tags
                        hero {
                            childImageSharp {
                                # Max width of preview cards is ~600
                                fluid(maxWidth: 600) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    timeToRead
                }
            }
        }
    }
`;
